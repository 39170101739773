<template>
  <div>
    <div class="flex flex-col justify-between mt-2 xl:flex-row">
      <h4 class="mt-0 leading-loose text-meta">
        {{ $t("legalBases") }}
      </h4>
      <el-button
        size="small"
        class="h-8 mt-0 has-icon"
        @click.prevent.stop="addLegalBase"
      >
        <SvgIcon name="add-circle" />
        {{ $t("addLegalBase") }}
      </el-button>
    </div>

    <ul>
      <li
        v-for="(base, i) in editingLegalBases"
        :key="i"
        class="pb-4 border-t border-gray-400"
      >
        <FormField :label="$t('legalBase')">
          <el-select v-model="base.id" @change="updateLegalBases">
            <el-option
              v-for="lbaseOption in legalBaseOptions"
              :key="`${lbaseOption.code}--${lbaseOption.id}`"
              class="max-w-sm md:max-w-2xl"
              :label="lbaseOption.name + ' ' + lbaseOption.code"
              :value="lbaseOption.id"
              :title="lbaseOption.name + ' ' + lbaseOption.code"
            >
            </el-option>
          </el-select>
        </FormField>
        <FormField :label="$t('designation')">
          <el-input
            v-model="base['example' + language]"
            @blur="updateLegalBases"
          ></el-input>
        </FormField>

        <el-button
          size="small"
          class="mt-4 has-icon"
          @click.prevent.stop="removeLegalBase(i)"
        >
          <SvgIcon name="delete-forever" />
          {{ $t("removeLegalBase") }}
        </el-button>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import FormField from "@/components/form/FormField.vue";
import { useLegalBasisStore } from "@/store/LegalBasis"

const legalBasisStore = useLegalBasisStore();

const props = defineProps({
  modelValue: {
    type: Array,
    required: true,
  },
  initialbase: {
    type: Array,
    required: true,
  },
  language: {
    type: String,
    default: "De",
    required: false,
  },
});

const editingLegalBases = ref(props.modelValue);

const emit = defineEmits(['update'])

const legalBaseOptions = computed(() => {
  if (!legalBasisStore.legalBases) {
    return [];
  }

  return legalBasisStore.legalBases;
});

const addLegalBase = () => {
  editingLegalBases.value.push({
    id: null,
    example: "",
    exampleDe: "",
    exampleFr: "",
    exampleIt: "",
    exampleRm: "",
  });
};

const removeLegalBase = (rowId) => {
  editingLegalBases.value.splice(rowId, 1);
  updateLegalBases();
};

const updateLegalBases = () => {
  emit("update", editingLegalBases.value);
};
</script>
