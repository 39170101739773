<template>
  <el-form
    v-if="data"
    ref="row"
    :model="data"
    :rules="rules"
    label-position="top"
  >
    <div class="catalog-grid offset-l">
      <div>
        <form-field :label="$t('title')" :prop="'title' + language">
          <el-input v-model="data['title' + language]" minlength="2" />
        </form-field>
      </div>

      <div>
        <form-field :label="$t('authorities')">
          <el-select
            v-model="data.legislativeAuthorities"
            value-key="id"
            multiple
            clearable
          >
            <el-option
              v-for="(authority, i) in authorityStore.authorityData"
              :key="'legislativeAuthorities' + i"
              :value-key="'legislativeAuthorities' + i"
              :label="authority.name"
              :value="authority"
            />
          </el-select>
        </form-field>
      </div>

      <div>
        <delegation-field
          v-model="data.delegation"
          :delegatable="data.delegationChoices.length > 0"
          :delegation-choices="data.delegationChoices"
        />
      </div>

      <div class="pr-8">
        <form-field :label="$t('responsibility')">
          <el-select
            v-model="data.executiveAuthorities"
            value-key="id"
            multiple
            clearable
          >
            <el-option
              v-for="(authority, i) in authorityStore.authorityData"
              :key="'executiveAuthorities' + i"
              :value-key="'executiveAuthorities' + i"
              :label="authority.name"
              :value="authority"
            ></el-option>
          </el-select>
        </form-field>
      </div>

      <div style="grid-row: span 99">
        <div class="seperator-x">
          <legal-bases-edit
            v-model="data.legalBases"
            :initialbase="data.legalBases"
            :language="language"
          />
        </div>
      </div>

      <div>
        <div class="seperator-x">
          <form-field :label="$t('access')">
            <el-select v-model="data.access" class="w-full">
              <el-option :label="$t('accessLabel.public')" value="public"></el-option>
              <el-option
                :label="$t('accessLabel.public_restricted')"
                value="public_restricted"
              ></el-option>
              <el-option
                :label="$t('accessLabel.private')"
                value="private"
              ></el-option>
            </el-select>
          </form-field>
          <form-field :label="$t('downloadService')" class="mt-8">
            <div>
              <el-radio v-model="data.downloadService" :label="true">{{
                $t("yes")
              }}</el-radio>
              <el-radio v-model="data.downloadService" :label="false">{{
                $t("no")
              }}</el-radio>
            </div>
          </form-field>
        </div>
      </div>

      <div>
        <div class="seperator-x">
          <form-field :label="$t('oereb')">
            <div>
              <el-radio v-model="data.oereb" :label="true">{{
                $t("yes")
              }}</el-radio>
              <el-radio v-model="data.oereb" :label="false">{{
                $t("no")
              }}</el-radio>
            </div>
          </form-field>
        </div>
      </div>

      <div>
        <div class="seperator-x">
          <form-field :label="$t('geoReference')">
            <div>
              <el-radio v-model="data.geoReference" :label="true">{{
                $t("yes")
              }}</el-radio>
              <el-radio v-model="data.geoReference" :label="false">{{
                $t("no")
              }}</el-radio>
            </div>
          </form-field>
        </div>
      </div>

      <div
        v-for="(field, index) in data.customFields"
        :key="'customfieldedit' + index"
      >
        <div class="seperator-x">
          <custom-field
            v-model="field.values['value' + language]"
            :type="field.type"
            :label="field.label"
          />
        </div>
      </div>
    </div>

    <!-- Actions buttons -->
    <div class="mt-4 pr-8 offset-l">
      <div class="flex justify-end mb-4 py-2 pr-8">
        <div class="flex items-end">
          <language-display :language="language" class="mr-3" />
          <el-popover
            v-if="isDraft(data) || isFeedback(data)"
            placement="bottom-end"
            trigger="click"
          >
            <textarea
              :id="'copyArea' + data.id"
              v-model="feedBackText"
              name="review"
              cols="30"
              rows="10"
            ></textarea>
            <template #reference>
              <el-button
                size="small"
                class="mx-2 has-icon"
                @click="generateFeedbackInfo"
              >
                <SvgIcon name="feedback" />
                {{ $t("feedback") }}
              </el-button>
            </template>
          </el-popover>

          <!-- slot="reference" -->
          <el-button
            v-if="isFeedback(data)"
            size="small"
            class="mx-2 has-icon"
            @click="setToDraft"
          >
            <SvgIcon name="feedback" />
            {{ $t("toDraft") }}
          </el-button>
          <el-button
            :disabled="!data.isSplittable"
            size="small"
            class="has-icon"
            @click="doSplit(data)"
          >
            <SvgIcon name="splitting" />
            {{ $t("splitting") }}
          </el-button>

          <el-dropdown
            split-button
            class="mx-2"
            size="small"
            trigger="hover"
            @command="deleteRow"
            @click="dismissEditAction(data)"
          >
            <span class="flex items-center">
              <SvgIcon name="cancel" class="mr-2 !h-auto !w-auto" />
              {{ $t("abort") }}
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item :disabled="!isDraft(data)" :command="data">
                  <SvgIcon
                    class="inline-block w-auto h-auto"
                    name="delete-forever"
                  />
                  {{ $t("quitAndDelete") }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>

          <form-field
            v-if="canBeRenumbered(data)"
            :label="$t('number')"
            class="mt-0 ml-5 mr-5 w-32"
            :title="$t('numberTooltip')"
            ><SvgIcon
              name="info"
              class="mr-2 pl-1 w-auto h-auto inline-block"
            />
            <el-input
              v-model="data.labelNumber"
              type="number"
              min="1"
              oninput="validity.valid||(value='');"
            ></el-input>
          </form-field>

          <el-button
            size="small"
            class="has-icon"
            :disabled="isDataUnchanged || hasEndDate(data)"
            @click="doSave(data)"
          >
            <SvgIcon name="check-circle" />
            {{ $t("save") }}
          </el-button>
        </div>
      </div>

      <div class="flex justify-end items-end py-2 pr-8 border-t">
        <form-field :label="$t('suspendDate')" class="mt-0 w-64">
          <el-config-provider :locale="pickerLocale">
            <el-date-picker
              v-model="suspendDate"
              type="date"
              class="text-gray-200"
              :placeholder="$t('datePicker.pickDate')"
              :disabled="hasEndDate(data) || !isPublished(data)"
              format="DD.MM.YYYY"
            ></el-date-picker
          ></el-config-provider>
        </form-field>
        <el-button
          size="small"
          class="has-icon ml-4 mb-2"
          :disabled="hasEndDate(data) || !isPublished(data)"
          @click="suspendRow(data)"
        >
          <SvgIcon name="details" />
          {{ $t("cancel") }}
        </el-button>

        <form-field
          v-if="isDraft(data) || isFeedback(data)"
          :label="$t('pubDate')"
          class="mt-0 ml-10 w-64"
        >
          <el-config-provider :locale="pickerLocale">
            <el-date-picker
              v-model="data.startDate"
              type="date"
              :placeholder="$t('datePicker.pickDate')"
              format="DD.MM.YYYY"
            ></el-date-picker
          ></el-config-provider>
        </form-field>
        <el-button
          v-if="isDraft(data) || isFeedback(data)"
          size="small"
          class="has-icon ml-4 mb-2"
          :disabled="!hasPublishDate"
          @click="doPublish"
        >
          <SvgIcon name="check-circle" />
          {{ $t("publish") }}
        </el-button>
      </div>
    </div>
  </el-form>
</template>

<script setup>
import { ref, computed, nextTick, h, onBeforeMount } from "vue";
import CustomField from "@/components/catalog/legalEdit/CustomField.vue";
import FormField from "@/components/form/FormField.vue";
import LanguageDisplay from "@/components/LanguageDisplay.vue";
import DelegationField from "@/components/catalog/legalEdit/DelegationField.vue";
import LegalBasesEdit from "@/components/catalog/legalEdit/LegalBases.vue";
import { ElMessageBox, ElNotification } from "element-plus";

import { notifyError } from "@/composables/notification.js";
import { confirm } from "@/composables/confirm.js";
import { cloneDeep } from "lodash";

import { LegislativeEntryLight } from "@/models/LegislativeEntryLight.model";
import {
  suspendLegalEntry,
  setReviewState,
  setDraftState,
  deleteLegalEntry,
} from "@/services/legislative-entry.js";
import {
  hasEndDate,
  isPublished,
  isFeedback,
  isDraft,
  canBeRenumbered,
  isEqualEditObject,
} from "@/services/utils.js";
import { LegislativeEntry } from "@/models/LegislativeEntry.model";
import {
  formatToStandardTextDate,
  parseDateFromServer,
} from "../../services/utils";

import { useAuthorityStore } from "@/store/Authority";
import { useLanguageStore } from "@/store/Language";
import { useDateFilter } from "@/store/Filters";
import { useCatalogListStore } from "@/store/CatalogList";
import { useLegislativeCatalogStore } from "@/store/LegislativeCatalog";

import de from "element-plus/dist/locale/de.mjs";
import fr from "element-plus/dist/locale/fr.mjs";
import it from "element-plus/dist/locale/it.mjs";
import { useI18n } from "vue-i18n";

const { t, locale } = useI18n();
const authorityStore = useAuthorityStore();
const languageStore = useLanguageStore();
const dateFilterStore = useDateFilter();
const catalogListStore = useCatalogListStore();
const legislativeCatalogStore = useLegislativeCatalogStore();

const props = defineProps({
  baseRow: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["published", "leave-editmode", "saved"]);

const suspendDate = ref(new Date());
const data = ref(null);
const feedBackText = ref("");
const language = ref("");
const originRow = ref("");
const rules = ref({
  title: [
    {
      required: true,
      message: t("FormValidation.notEmpty"),
      trigger: "blur",
    },
  ],
  titleDe: [
    {
      required: true,
      message: t("FormValidation.notEmpty"),
      trigger: "blur",
    },
  ],
  titleFr: [
    {
      required: true,
      message: t("FormValidation.notEmpty"),
      trigger: "blur",
    },
  ],
  titleIt: [
    {
      required: true,
      message: t("FormValidation.notEmpty"),
      trigger: "blur",
    },
  ],
});

// Computed
const pickerLocale = computed(() => {
  if (locale.value == "fr") {
    return fr;
  } else if (locale.value == "it") {
    return it;
  } else {
    return de;
  }
});

const isDataUnchanged = computed(() => {
  if (!isEqualEditObject(originRow.value, data.value)) {
    catalogListStore.addIdToUnsavedChanges(data.value.id);
  } else {
    catalogListStore.removeIdFromUnsavedChanges(data.value.id);
  }
  return isEqualEditObject(originRow.value, data.value);
});

const hasPublishDate = () => {
  return data.value.startDate != null;
};

// Methods
const generateFeedbackInfo = () => {
  let src = data.value;
  let text = "";
  // add url
  let reviewURL = `${window.location.origin}/review/${src.id}`;
  // add header, replace the url with the correct feedback url
  text += t("feedbackHeader").replace("http://www.geobasisdaten.ch", reviewURL);
  text += "\n\n\n";
  // add row number looking like this: Rechtlicher Datensatz ID 73A
  text += t("legalEntryDataset") + " " + t("id") + " " + src.identifier;
  // add line
  text += "\n";
  text += t("feedbackLine");
  text += "\n";
  // add legal fields
  text += t("feedbackFieldsLegal");
  text += "\n";
  // add custom fields
  src.customFields.forEach((field) => {
    text += field.label + ":";
    text += "\n";
  });
  text += "\n\n";
  // add each technical field
  data.value.technicalEntries.forEach((entry) => {
    // dd the header like this: Technischer Datensatz ID 73A.1
    text += t("technicalEntryDataset") + " " + t("id") + " " + entry.label;
    // add line
    text += "\n";
    text += t("feedbackLine");
    text += "\n";
    // add technical entry fields
    text += t("feedbackFieldsTechnical");
    text += "\n";
    // add custom fields
    entry.customFields.forEach((field) => {
      text += field.label + ":";
      text += "\n";
    });
    text += "\n\n";
  });
  // add footer
  text += "\n";
  text += t("feedbackFooter");
  // set the text
  feedBackText.value = text;
  setReviewState(data.value)
    .then(() => {
      data.value.status = "Feedback";
    })
    .catch((error) => {
      notifyError(error);
      // eslint-disable-next-line no-use-before-define
      console.error(error.message);
    });
  copyText(text);
};

const setToDraft = () => {
  setDraftState(data.value)
    .then(() => {
      //props.baseRow.status = "Draft";
      data.value.status = "Draft";
    })
    .catch((error) => {
      notifyError(error);
      // eslint-disable-next-line no-use-before-define
      console.error(error.message);
    });
};

const copyText = () => {
  nextTick(async () => {
    // set timeout so that the value has correctly been filled
    await setTimeout(() => {
      let textToCopy = document.querySelector("#copyArea" + data.value.id);
      textToCopy.setAttribute("type", "text");
      textToCopy.select();
      document.execCommand("copy");
      /* unselect the range */
      textToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    }, 0);
    //display the successmessage in form of a notification
    notifyError({
      title: t("feedback"),
      message: h("span", { style: "color: teal" }, t("toClipboard")),
    });
  });
};

const doPublish = async () => {
  // Convert the date to ISO for further handling
  const formattedDate = formatToStandardTextDate(
    parseDateFromServer(data.value.startDate)
  );
  const dataNew = {
    pk: data.value.id,
    startDate: formattedDate,
  };
  const saveData = data.value;
  try {
    let response = await legislativeCatalogStore.saveRecord(saveData);
    await legislativeCatalogStore.publishRecord(dataNew).then(() => {
      // we get the data from the save request and not from the publish request,
      // because the publish api does not return the full object. Therefore we
      // need to update the status manually here, as a little nice hack.
      response.data.status = "Published";
      legislativeCatalogStore.addPublishedLegalRow(
        new LegislativeEntryLight(response.data)
      );

      emit("published");
    });
  } catch (error) {
    let errorMsg = error.response.data;
    if (
      error.response &&
      error.response.data &&
      error.response.data["nonFieldErrors"]
    ) {
      const emptyField = error.response.data["nonFieldErrors"][0];
      errorMsg = t("FormValidation.notEmpty") + ": " + t(emptyField);
    }
    notifyError({
      title: t("saveError"),
      message: errorMsg,
    });
  }
};

const doSplit = async (row) => {
  await legislativeCatalogStore
    .splitRecord(row)
    .then(() => {
      // leave editing mode
      emit("leave-editmode", row);
      //display the successmessage in form of a notification
      ElNotification({
        title: t("splitting"),
        message: h("span", { style: "color: teal" }, t("splitSuccess")),
      });
      // refresh data
      legislativeCatalogStore.retrieveLegislativeList();
    })
    .catch((error) => {
      notifyError({
        title: t("splittingErrorTitle"),
        message: t("splittingErrorMessage"),
      });
      // eslint-disable-next-line no-use-before-define
      console.error(error);
    });
};

const doSave = (row) => {
  const dataNew = data.value;
  const oldLabelNumber = data.value.labelNumber;
  legislativeCatalogStore
    .saveRecord(dataNew)
    .then((response) => {
      catalogListStore.removeIdFromUnsavedChanges(dataNew.id);
      let new_entry = new LegislativeEntryLight(response.data);

      // if the saved element is a published entry we
      // want to reload the data so we see the newly created draft
      if (dataNew.status.toLowerCase() === "published") {
        legislativeCatalogStore.addDraftLegalRow(new_entry);
      } else if (response.data.labelNumber !== oldLabelNumber) {
        // if the number has changed, the position of the entry was moved. As this is a rear
        // use case, we will just reload the list instead of moving the entry manually
        legislativeCatalogStore.retrieveLegislativeList();
      } else {
        legislativeCatalogStore.replaceLegalRow(new_entry);
      }
      //display the successmessage in form of a notification
      ElNotification({
        title: t("legalEntryDataset") + " " + dataNew.identifier,
        message: h("span", { style: "color: teal" }, t("legalEntrySuccess")),
      });
      // leave editing mode
      emit("leave-editmode", row);
      emit("saved", row);
    })
    .catch((error) => {
      const data = error.response.data;
      let errorMsg = "";
      if ("nonFieldErrors" in data) {
        const emptyField = data["nonFieldErrors"][0];
        errorMsg = t("FormValidation.notEmpty") + ": " + t(emptyField);
      } else {
        errorMsg = data;
      }
      notifyError({
        title: t("saveError"),
        message: errorMsg,
      });
      // eslint-disable-next-line no-use-before-define
      console.error(error.message);
    });
};

const suspendRow = (row) => {
  let suspendAction = () => {
    suspendLegalEntry(row.id, suspendDate.value)
      .then((response) => {
        if (dateFilterStore.dateUntil > new Date(response.data.endDate)) {
          legislativeCatalogStore.removeLegalRow(response.data.id);
        } else {
          const data = row
          data.endDate = response.data
          legislativeCatalogStore.replaceLegalRow(
            new LegislativeEntry(data)
          );
        }
        //display the successmessage in form of a notification
        ElNotification({
          title: t("cancel"),
          message: h("span", { style: "color: teal" }, t("cancelSuccess")),
        });
      })
      .catch((error) => {
        if (new Date(row.startDate) > suspendDate.value) {
          ElNotification.error({
            title: t("suspendError"),
            message: error.response.data.detail,
          });
        } else {
          notifyError(t("genericError"));
        }
      });
  };
  confirm(t, t("irreversible"), t("cancel"), suspendAction);
};

const deleteCallback = (entryId) => {
  //display the successmessage in form of a notification
  ElNotification({
    title: t("deleteAction"),
    message: h("span", { style: "color: teal" }, t("cancelSuccess")),
  });
  // remove the deleted entry out of the list
  legislativeCatalogStore.removeLegalRow(entryId);
};

const deleteRow = (row) => {
  // promt the user
  ElMessageBox.confirm(t("irreversible"), t("cancel"), {
    confirmButtonText: t("yes"),
    cancelButtonText: t("no"),
    type: "warning",
  }).then(() => {
    // suspend it on yes
    deleteLegalEntry(row.id).then(
      () => {
        deleteCallback(row.id);
      },
      (error) => {
        notifyError(error);
      }
    );
  });
};

const dismissEditAction = (row) => {
  catalogListStore.removeIdFromUnsavedChanges(data.value.id);
  emit("leave-editmode", row);
};

// created
onBeforeMount(() => {
  data.value = cloneDeep(props.baseRow);
  // first letter needs to be uppercased
  let lang =
    data.value.lang ||
    languageStore.getLanguage ||
    localStorage.getItem("lang") ||
    "De";
  language.value = lang.charAt(0).toUpperCase() + lang.slice(1);
  // convert the selected legal bases a bit
  data.value.legalBases = data.value.legalBases.map((value) => {
    return {
      id: value.id,
      example: value.example,
      exampleDe: value.exampleDe,
      exampleFr: value.exampleFr,
      exampleIt: value.exampleIt,
    };
  });
  originRow.value = cloneDeep(props.baseRow);
});
</script>

<style lang="postcss" scoped></style>
